/** React */
/** MUI */
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';

/** Components */
import HighlightContainer from '../../Common/HighlightContainer';
import useConsentFormsStyles from '../ConsentFormsStyles';
import TranslationModal from './TranslationModal';

const Text = ({ children, className = [] }) => {
    const classes = useConsentFormsStyles();
    return (
        <Typography variant="body1" className={clsx(classes.paragraph, ...className)}>
            {children}
        </Typography>
    );
};

const TranslationAlert = ({ text = '', underlineText = '', modalContent = '' }) => {
    const classes = useConsentFormsStyles();
    const [openTranslation, setOpenTranslation] = useState(false);
    const handleOpenTranslation = () => setOpenTranslation(true);
    const handleCloseTranslation = () => setOpenTranslation(false);

    const underlineClasses = [
        classes.underlineButton,
        classes.paragraph,
        classes.dInlineBlock,
        classes.fontRegular,
        classes.cursorPointer,
    ];

    return (
        <HighlightContainer>
            <Text>
                {`${text} `}
                {/* Popup Button */}
                <div onClick={handleOpenTranslation} className={clsx(...underlineClasses)}>
                    {underlineText}
                </div>
            </Text>
            <TranslationModal open={openTranslation} onClose={handleCloseTranslation} modalContent={modalContent} />
        </HighlightContainer>
    );
};

export default TranslationAlert;
