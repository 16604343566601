import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ContentDivider from '@/components/Common/ContentDivider';
import HighlightContainer from '@/components/Common/HighlightContainer';
import { SignatureView, SummaryIntro, SummaryPersonalInfoTreatment } from '@/components/ConsentForms';
import useConsentFormsStyles from '@/components/ConsentForms/ConsentFormsStyles';

import { ConsentFormsContext, signEnum } from '@/context/ConsentFormsContext';

import useScrollToTop from '@/hooks/useScrollToTop';

import { formatRawDate } from '@/utils/helpers.js';

import { useSearchStore } from '@/store.js';

import ContractDetailsPage from './ContractDetailsPage';

const dateText = (text, date) => {
    return `${text}, ${date}`;
};

const SummaryAndConsentPage = () => {
    /** Styles */
    const classes = useConsentFormsStyles();

    const {
        consentFormsContent: content,
        signature,
        setOpenSignModal,
        signState,
        professional,
    } = useContext(ConsentFormsContext);
    const { i18n } = useTranslation();

    const professionals = useSearchStore((state) => state.professionals);
    const allDocuments = useSearchStore((state) => state.allDocuments);

    /** Scroll to top on mount */
    useScrollToTop();

    return (
        <>
            {/* Intro */}
            <SummaryIntro />
            {/* Divider */}
            <ContentDivider my={30} />
            {/* Personal Information */}
            <SummaryPersonalInfoTreatment />
            {/* Divider */}
            <ContentDivider my={30} />
            {/* Contract Details Component */}
            <ContractDetailsPage />
            {/* Signature */}
            {signState === signEnum.DIRECT_SIGN ? (
                <HighlightContainer bgColor="#F5F5F5" classNames={[classes.br5]}>
                    <Typography className={clsx(classes.secondaryHeading, classes.textCenter)}>
                        {content?.corterierContractDetails?.paperSignText}
                    </Typography>
                </HighlightContainer>
            ) : signature ? (
                <SignatureView
                    text={dateText(
                        allDocuments[professionals[professional?.key]?.location_hub?.id]?.city[0].text,
                        formatRawDate(new Date(), i18n)
                    )}
                    signature={signature}
                    openPopup={() => setOpenSignModal(true)}
                />
            ) : null}
        </>
    );
};

export default SummaryAndConsentPage;
