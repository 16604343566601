/** React */
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

/** Components */
import useConsentFormsStyles from '../ConsentFormsStyles';
import Heading from './Heading';
import Text from './Text';

const ContractDetailsWithLogo = ({
    heading = '',
    content = '',
    logo = null,
    logoHeight = '',
    logoWidth = '',
    bottomHeading = '',
}) => {
    const classes = useConsentFormsStyles();

    return (
        <div className={classes.mt20}>
            {/* Flex will be applied accordingly */}
            <div>
                <div
                    className={clsx(
                        logo ? [classes.dFlex, classes.contentBetween, classes.itemsEnd] : null,
                        classes.mb25
                    )}
                >
                    {heading && <Typography style={{ whiteSpace: 'pre-wrap' }}>{heading}</Typography>}
                    {logo && <img src={logo} height={logoHeight} width={logoWidth} />}
                </div>
                {content && <Text style={{ whiteSpace: 'pre-wrap' }}>{content}</Text>}
            </div>
            {/* Bottom Heading */}
            {bottomHeading ? <Heading classes={[classes.mb15]}>{bottomHeading}</Heading> : null}
        </div>
    );
};

export default ContractDetailsWithLogo;
