import { makeStyles } from '@material-ui/core/styles';

const useConsentFormsStyles = makeStyles((theme) => ({
    // margin
    m0: { margin: 0 },
    m5: { margin: 5 },
    m10: { margin: 10 },
    m15: { margin: 15 },
    m20: { margin: 20 },
    m25: { margin: 25 },
    m30: { margin: 30 },

    // margin top
    mt0: { marginTop: '0px !important' },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt15: { marginTop: 15 },
    mt20: { marginTop: 20 },
    mt25: { marginTop: 25 },
    mt30: { marginTop: 30 },

    // margin bottom
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb15: { marginBottom: 15 },
    mb20: { marginBottom: 20 },
    mb25: { marginBottom: 25 },
    mb30: { marginBottom: 30 },
    mb35: { marginBottom: 35 },
    mb40: { marginBottom: 40 },

    // margin block Y
    my5: { marginBlock: 5 },
    my10: { marginBlock: 10 },
    my15: { marginBlock: 15 },
    my20: { marginBlock: 20 },
    my30: { marginBlock: 30 },

    // margin inline X
    mxAuto: { marginInline: 'auto' },
    mx10: { marginInline: 10 },
    mx20: { marginInline: 20 },
    mx30: { marginInline: 30 },
    mx40: { marginInline: 40 },
    mx50: { marginInline: 50 },

    // padding top
    pt10: { paddingTop: 10 },
    pt15: { paddingTop: 15 },
    pt20: { paddingTop: 20 },
    pt25: { paddingTop: 25 },
    pt30: { paddingTop: 30 },

    // padding bottom
    pb10: { paddingBottom: 10 },
    pb15: { paddingBottom: 15 },
    pb20: { paddingBottom: 20 },
    pb25: { paddingBottom: 25 },
    pb30: { paddingBottom: 30 },

    // padding right
    pr5: { paddingRight: 5 },
    pr10: { paddingRight: 10 },
    pr15: { paddingRight: 15 },
    pr20: { paddingRight: 20 },
    pr25: { paddingRight: 25 },
    pr30: { paddingRight: 30 },

    // padding left
    pl5: { paddingLeft: 5 },
    pl10: { paddingLeft: 10 },
    pl15: { paddingLeft: 15 },
    pl20: { paddingLeft: 20 },
    pl25: { paddingLeft: 25 },
    pl30: { paddingLeft: 30 },

    // padding block Y
    py10: { paddingBlock: 10 },
    py15: { paddingBlock: 15 },
    py20: { paddingBlock: 20 },
    py25: { paddingBlock: 25 },
    py30: { paddingBlock: 30 },
    py40: { paddingBlock: 40 },

    // padding inline X
    px10: { paddingInline: 10 },
    px20: { paddingInline: 20 },
    px24: { paddingInline: 24 },
    px30: { paddingInline: 30 },
    px40: { paddingInline: 40 },
    px50: { paddingInline: 50 },

    br5: { borderRadius: 5 },

    lhNormal: { lineHeight: 'normal !important' },

    //* * Height */
    h25: { height: '25%' },
    h50: { height: '50%' },
    h75: { height: '75%' },
    h100: { height: '100%' },

    hScreen: { height: '100vh' },

    //* * Custom Heights */
    minHeight: { minHeight: (h) => `${h}px` },
    maxHeight: { maxHeight: (h) => `${h}px` },
    height: { height: (h) => `${h}px` },

    //* * Width */
    w25: { width: '25%' },
    w35: { width: '35%' },
    w50: { width: '50%' },
    w75: { width: '75%' },
    w80: { width: '80%' },
    w90: { width: '90%' },
    w100: { width: '100%' },

    // Position
    absolutePosition: { position: 'absolute' },
    relativePosition: { position: 'relative' },
    fixedPosition: { position: 'fixed' },

    bold: {
        fontFamily: 'MessinaSans-SemiBold !important',
    },

    fontRegular: { fontFamily: 'MessinaSans-Regular !important' },

    cursorPointer: { cursor: 'pointer' },

    wordBreak: { wordBreak: 'break-all' },

    transformNone: { textTransform: 'none' },
    transformLowercase: { textTransform: 'lowercase' },
    transformUppercase: { textTransform: 'uppercase' },

    thankYouHeading: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: 28,
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },

    fontXl: {
        fontSize: '26px !important',
    },

    mainHeading: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: 21,
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },

    secondaryHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
    },

    heading: {
        ...theme.typography.HL1,
    },

    subHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '18px',
        textTransform: 'none',
        color: theme.palette.common.darkGrey,
    },

    fontSerif: { fontFamily: 'MessinaSerif-Regular !important' },

    textCenter: { textAlign: 'center' },
    textMuted: { color: `${theme.palette.common.brown} !important` },

    center: { margin: 'auto' },

    whiteSpacePreLine: { whiteSpace: 'pre-line' },

    paragraph: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem !important',
        lineHeight: '24px',
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('sm')]: {
            fontSize: '.9rem !important',
        },
    },

    wrapText: { wordWrap: 'break-word' },

    alignSelf: { alignSelf: 'center' },

    translateCenter: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

    /** FlexBox */
    flexBox: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    dFlex: { display: 'flex' },
    dInlineBlock: { display: 'inline-block' },
    flexWrap: { flexWrap: 'wrap' },
    gap4: { gap: 4 },
    gap5: { gap: 5 },
    gap10: { gap: 10 },
    gap15: { gap: 15 },
    gap20: { gap: 20 },
    flexRow: { flexDirection: 'row' },
    flexCol: { flexDirection: 'column' },
    directionCol: { flexDirection: 'column' },
    directionRow: { flexDirection: 'row' },

    itemsStart: { alignItems: 'flex-start' },
    itemsCenter: { alignItems: 'center' },
    itemsEnd: { alignItems: 'flex-end' },
    itemsStretch: { alignItems: 'stretch' },

    contentStart: { justifyContent: 'flex-start' },
    contentEnd: { justifyContent: 'flex-end' },
    contentCenter: { justifyContent: 'center' },
    contentBetween: { justifyContent: 'space-between' },

    /** Welcome Component Styles */
    welcomeContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
        width: '100%',
        margin: 'auto',
        marginBlock: '1rem',
        maxWidth: theme.layout.contentWidth,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            paddingInline: '1rem',
        },
    },
    welcomeFooter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 30,
    },
    btnOutlineContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 15,
    },
    btn: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '.8rem',
        },
    },
    welcomeImageContainer: {
        objectFit: 'contain',
    },
    welcomeImage: {
        height: 64,
        width: 64,
    },

    underlineButton: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        textTransform: 'none',
        textDecorationLine: 'underline',
        textDecorationColor: theme.palette.common.yellow,
        textDecorationThickness: 2,
        color: theme.palette.common.darkGrey,
        textUnderlineOffset: '0.25em',
        '&:hover, &:focus, &:active': { textDecorationThickness: 3 },
    },

    //* Main Container */
    ContainerBody: {
        maxWidth: theme.layout.contentWidth,
        margin: '0 auto',
        paddingLeft: 50,
        paddingRight: 50,
        marginTop: 40,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 0,
        },
    },

    sidebarMenu: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },

    progressText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.darkGrey,
        width: 300,
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap',
        },
    },

    progressContainer: { width: 200, marginBottom: 24 },

    footerButtons: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    shortHeading: {
        ...theme.typography.HL4,
        textTransform: 'uppercase',
    },

    summaryPersonalInfo: {
        position: 'relative',
    },

    editButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },

    personalInfoIcon: {
        width: 50,
        height: 50,
    },

    editIcon: {
        width: 17,
        height: 17,
    },

    personalInfoHeader: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },

    personalInfoData: {
        display: 'flex',
    },

    personalInfoDataFields: {
        wordBreak: 'break-all',
    },

    //* * Signature Modal Styles */
    dialogRoot: {
        backgroundColor: 'transparent',
    },

    dialogAction: {
        position: 'absolute',
        top: 5,
        right: 0,
    },

    dialogContent: {
        minHeight: '224px',
    },

    closeButton: {
        width: 20,
        height: 20,
    },
    tabsRoot: {
        backgroundColor: '#F5F5F5',
        width: '100%',
    },
    tabsHeader: {
        boxShadow: 'none',
    },
    tabs: {
        backgroundColor: '#ffffff',
        '& .Mui-selected': {
            color: theme.palette.common.darkGrey,
            backgroundColor: '#F5F5F5',
            paddingBlock: 13,
            marginBottom: '-1px',
        },
        '& .MuiTabs-flexContainer': {
            width: '99.6%',
            alignItems: 'flex-end',
            borderBottom: '1px solid #D5D5D5',
        },
    },
    tab: {
        minHeight: 50,
        color: '#ABABAB',
        paddingInline: 18,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        borderLeft: '1px solid #D5D5D5',
        borderTop: '1px solid #D5D5D5',
        borderRight: '1px solid #D5D5D5',
        '& .MuiTab-wrapper': {
            display: 'flex !important',
            flexDirection: 'row',
            gap: 8,
            fontFamily: 'MessinaSerif-Regular',
            fontSize: '1rem',
            textTransform: 'none',
        },
    },
    tabPanel: {
        minHeight: '224px',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderLeft: '1px solid #D5D5D5',
        borderRight: '1px solid #D5D5D5',
        borderBottom: '1px solid #D5D5D5',
    },

    signContainer: {
        borderRadius: 5,
        border: '1px solid #D5D5D5',
        backgroundColor: '#F5F5F5',
        padding: 20,
    },

    //* * Signature Pad **/
    signCanvasHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    restartBtn: {
        color: '#ABABAB',
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        paddingBlock: 0,
    },

    signModalTextBoxContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 15,
        '& div': { flex: 1 },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },

    textUnSelect: {
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },

    digitalSignature: {
        fontFamily: 'TheDoctor',
        fontSize: 60,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
        },
    },

    btnMobile: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '.7rem !important',
        },
    },

    mobileSwichContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },

    mobilePanel: {
        backgroundColor: '#F5F5F5',
        borderBlock: '1px solid #D5D5D5',
        width: '100%',
    },

    mobilePanelHeight: {
        height: '224px',
    },

    mobileModalContent: {
        [theme.breakpoints.down('xs')]: {
            paddingInline: 0,
        },
    },

    codeContainer: {
        '& div': {
            gap: 20,
            [theme.breakpoints.down('xs')]: {
                gap: 10,
            },
        },
    },

    colorError: {
        color: theme.palette.common.red,
    },

    errorText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: '1.5rem',
        color: theme.palette.common.red,
    },

    CodeBox: {
        '& input:focus': {
            border: '1px solid #000000 !important',
        },
    },
}));

export default useConsentFormsStyles;
