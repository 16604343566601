// theme.palette.common.cream_disable;
import { makeStyles } from '@material-ui/core';

export const useColorStyles = makeStyles((theme) => ({
    // background colors
    bg_creamDisable: {
        backgroundColor: theme.palette.common.cream_disable,
    },
    // margin
    m0: { margin: 0 },
    m5: { margin: 5 },
    m10: { margin: 10 },
    m15: { margin: 15 },
    m20: { margin: 20 },
    m25: { margin: 25 },
    m30: { margin: 30 },

    // margin top
    mt0: { marginTop: 0 },
    mt5: { marginTop: 5 },
    mt10: { marginTop: 10 },
    mt15: { marginTop: 15 },
    mt20: { marginTop: 20 },
    mt25: { marginTop: 25 },
    mt30: { marginTop: 30 },

    // margin left
    ml0: { marginLeft: 0 },
    ml5: { marginLeft: 5 },
    ml10: { marginLeft: 10 },
    ml15: { marginLeft: 15 },
    ml20: { marginLeft: 20 },
    ml25: { marginLeft: 25 },
    ml30: { marginLeft: 30 },

    // margin bottom
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb15: { marginBottom: 15 },
    mb20: { marginBottom: 20 },
    mb25: { marginBottom: 25 },
    mb30: { marginBottom: '30px !important' },
    mb35: { marginBottom: 35 },
    mb40: { marginBottom: 40 },

    // margin block Y
    my5: { marginBlock: 5 },
    my10: { marginBlock: 10 },
    my15: { marginBlock: 15 },
    my20: { marginBlock: 20 },
    my30: { marginBlock: 30 },

    // margin inline X
    mx10: { marginInline: 10 },
    mx20: { marginInline: 20 },
    mx30: { marginInline: 30 },
    mx40: { marginInline: 40 },
    mx50: { marginInline: 50 },

    // padding
    p10: { padding: 10 },
    p15: { padding: 15 },
    p20: { padding: 20 },
    p25: { padding: 25 },
    p30: { padding: 30 },

    // padding top
    pt10: { paddingTop: 10 },
    pt15: { paddingTop: 15 },
    pt20: { paddingTop: 20 },
    pt25: { paddingTop: 25 },
    pt30: { paddingTop: 30 },

    // padding right
    pr5: { paddingRight: 5 },
    pr10: { paddingRight: 10 },
    pr15: { paddingRight: 15 },
    pr20: { paddingRight: 20 },
    pr25: { paddingRight: 25 },
    pr30: { paddingRight: 30 },

    // padding left
    pl5: { paddingLeft: 5 },
    pl10: { paddingLeft: 10 },
    pl15: { paddingLeft: 15 },
    pl20: { paddingLeft: 20 },
    pl25: { paddingLeft: 25 },
    pl30: { paddingLeft: 30 },

    // padding block Y
    pb10: { paddingBlock: 10 },
    pb15: { paddingBlock: 15 },
    pb20: { paddingBlock: 20 },
    pb25: { paddingBlock: 25 },
    pb30: { paddingBlock: 30 },

    // padding inline X
    px10: { paddingInline: 10 },
    px20: { paddingInline: 20 },
    px24: { paddingInline: 24 },
    px30: { paddingInline: 30 },
    px40: { paddingInline: 40 },
    px50: { paddingInline: 50 },

    // border radius
    b10: { borderRadius: 10 },
    b15: { borderRadius: 15 },

    // display
    dFlex: { display: 'flex' },

    // border color
    br1: { border: `2px solid ${theme.palette.common.lightBrownSelected} ` },

    // paragraph clamp
    paragraphClamp: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        [theme.breakpoints.down('xs')]: {
            WebkitLineClamp: 3,
        },
    },
}));
