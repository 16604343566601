import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useContext } from 'react';

import RegistrationConsent from '@/components/RegistrationConsent.jsx';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import { getText } from '@/utils/helpers.js';

import PasswordField from '../../Common/PasswordField.jsx';
import useConsentFormsStyles from '../ConsentFormsStyles.js';

const SetPassword = ({ content, accountContent }) => {
    const classes = useConsentFormsStyles();

    //* * Context */
    const {
        consentFormsContent: mainContent,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        passwordError,
        confirmPasswordError,
        policyAccepted,
        setPolicyAccepted,
        analyticsAccepted,
        setAnalyticsAccepted,
        validatePassword,
    } = useContext(ConsentFormsContext);

    return (
        <div>
            <Typography variant="body1" className={clsx(classes.paragraph, classes.mt30, classes.mb10)}>
                {getText(accountContent.enter_a_password) || 'Gib hier ein Passwort für dein Konto an.'}
            </Typography>
            <div>
                <div>
                    <PasswordField
                        label={content.password_label || 'Password'}
                        value={password}
                        onChange={setPassword}
                        errorMessage={mainContent?.heraklesContent?.[passwordError]}
                        validatePassword={validatePassword}
                        passwordOptionHeading={content.passwords_option_heading}
                        optionOne={content.passwords_options[0].text}
                        optionTwo={content.passwords_options[1].text}
                        optionThree={content.passwords_options[2].text}
                    />
                </div>
                <div>
                    <PasswordField
                        label={content.confirm_password_label || 'Confirm Password'}
                        value={confirmPassword}
                        onChange={setConfirmPassword}
                        errorMessage={mainContent?.heraklesContent?.[confirmPasswordError]}
                        validatePassword={validatePassword}
                        hideValidMessage
                    />
                </div>
                <RegistrationConsent
                    isConsentConfirmed={policyAccepted}
                    setIsConsentConfirmed={setPolicyAccepted}
                    isAnalyticsConfirmed={analyticsAccepted}
                    setIsAnalyticsConfirmed={setAnalyticsAccepted}
                />
            </div>
        </div>
    );
};

export default SetPassword;
