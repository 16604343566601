import { Grid, Typography } from '@material-ui/core';
import { useContext } from 'react';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import useConsentFormsStyles from '../../ConsentForms/ConsentFormsStyles';
import { DropDown } from '../DropDown/DropDown';
import TextInputField from '../TextInputField';

const ContactInformation = ({
    heading,
    /** Form States */
    formData,
    handleChange,
    /** Labels */
    labels,
    /** Country */
    isCountry = false,
    setCountry = () => {},
}) => {
    const classes = useConsentFormsStyles();

    /** Context */
    const { consentFormsContent: mainContent, errors, setErrors, validateForm } = useContext(ConsentFormsContext);

    /** Destructured labels */
    const { streetLabel, zipCodeLabel, cityLabel, countryLabel } = labels;

    /** Destructured context form states */
    const { street, zipCode, city, country } = formData;

    const validateCountry = (type, country) => {
        const countryValue = country?.code;
        setErrors((err) => ({
            ...err,
            countryError: '',
        }));

        if (!countryValue) {
            setErrors((err) => ({
                ...err,
                countryError: 'Required country',
            }));
        }
    };

    return (
        <Grid container spacing={1}>
            {heading ? <Typography className={classes.secondaryHeading}>{heading}</Typography> : null}
            <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} direction="row">
                    <TextInputField
                        label={streetLabel || 'Street & Number'}
                        name="street"
                        value={street}
                        onChange={handleChange}
                        validateForm={validateForm}
                        errorName="street"
                        errorMessage={mainContent?.heraklesContent?.[errors?.streetError]}
                        formData={formData}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} direction="row">
                <Grid item md={3} xs={12}>
                    <TextInputField
                        label={zipCodeLabel || 'Zip Code'}
                        name="zipCode"
                        value={zipCode}
                        onChange={handleChange}
                        validateForm={validateForm}
                        errorName="zipCode"
                        errorMessage={mainContent?.heraklesContent?.[errors?.zipCodeError]}
                        formData={formData}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>
                <Grid item md={9} xs={12}>
                    <TextInputField
                        label={cityLabel || 'City'}
                        name="city"
                        value={city}
                        onChange={handleChange}
                        validateForm={validateForm}
                        errorName="city"
                        errorMessage={mainContent?.heraklesContent?.[errors?.cityError]}
                        formData={formData}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>
            </Grid>
            {isCountry && (
                <Grid container spacing={1} direction="row">
                    <Grid item md={12} xs={12}>
                        <DropDown
                            type="country"
                            mainDataHeading={countryLabel || 'Country'}
                            setData={setCountry}
                            data={country?.code?.toLocaleUpperCase()}
                            compulsory="country"
                            widthID="gender"
                            error={mainContent?.heraklesContent?.[errors?.countryError]}
                            validateForm={validateCountry}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default ContactInformation;
