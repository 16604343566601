import { uniqBy } from 'es-toolkit';
import { useContext, useMemo } from 'react';
/** Router */
import { useHistory } from 'react-router-dom';

import WelcomeIcon from '@/assets/images/HandwritingCircles.svg';

/** Child Components */
import { WelcomeComponent } from '@/components/ConsentForms';
import Loader from '@/components/Loader';

/** Context */
import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import usePrismicData from '@/hooks/usePrismicData';

import { getText } from '@/utils/helpers.js';

import { usePersistedPrismicStore } from '@/store.js';

const description =
    'Please read the following documents thoroughly and fill them out. After completing the required sections, these documents will be forwarded to your practitioner immediately.';

const Welcome = () => {
    const history = useHistory();
    const consentFormsTreatment = usePersistedPrismicStore((state) => state.consentFormsTreatment);
    const heraklesAllForms = usePersistedPrismicStore((state) => state.heraklesAllForms);
    const { loading: heraklesLoading, content: heraklesContent } = usePrismicData(heraklesAllForms);
    const { loading, content } = usePrismicData(consentFormsTreatment, 'consent_forms_-_welcome_page');

    //* * Context */
    const { isAllForms, allFormsPages, movePage, whichEnum, setIsFromOwnWelcome } = useContext(ConsentFormsContext);
    const {
        state: { user },
    } = useContext(AuthContext);

    const handleContinueWithAccount = () => {
        setIsFromOwnWelcome(true);
        if (isAllForms) {
            if (user) {
                //* * Setting Next Page */
                movePage(whichEnum.NEXT, history);
                return false;
            }
            history.push(`/login?redirectTo=${allFormsPages[1]?.route}`);
        } else if (user) history.push('/consent-privacy?step=personal-information');
        else history.push('/login?redirectTo=consent-privacy?step=personal-information');
    };

    const handleContinueAsGuest = () => {
        setIsFromOwnWelcome(true);
        if (!isAllForms) {
            history.push('/consent-privacy?step=personal-information');
            return;
        }
        //* * Setting Next Page */
        movePage(whichEnum.NEXT, history);
    };

    const continueText = useMemo(() => {
        return !loading ? getText(content?.continue_as_guest_title) : 'You don’t have an Eterno account?';
    }, [loading, content]);

    if (loading || heraklesLoading) return <Loader />;
    return (
        <WelcomeComponent
            width="70% !important"
            icon={WelcomeIcon}
            pages={uniqBy(allFormsPages?.slice(1, -1), (page) => page.name)}
            title={getText(heraklesContent?.extended_treatment_contract_title)}
            paragraphs={[getText(heraklesContent?.ext_treatment_contract_subtitle_text)]}
            primaryBtnText={getText(content?.continue_with_account_cta)}
            {...(!user && { continueText: continueText })}
            continueBtnText={getText(content?.continue_as_guest_cta)}
            onPrimaryBtnClick={handleContinueWithAccount}
            onContinueBtnClick={handleContinueAsGuest}
        />
    );
};

export default Welcome;
