import { dayjs } from '@/utils/dayjsSetup.js';

export const getHoursAndMinutes = (date) => {
    const parsedDate = dayjs(date);

    if (!parsedDate.isValid()) return '';

    return parsedDate.format('HH:mm');
};

/**
 * @param {number} currentYear
 * @param {string} date
 * @returns {string}
 */
export const limitYearToCurrent = (currentYear, date) => {
    if (!date) return date;

    const dateParts = date.split('-');
    const year = parseInt(dateParts[0], 10);
    if (year <= currentYear) return date;

    dateParts[0] = currentYear.toString();
    return dateParts.join('-');
};
