/** React */
/** MUI */
import { Typography } from '@material-ui/core';
/** Others */
import clsx from 'clsx';

/** Components */
import useConsentFormsStyles from '../ConsentFormsStyles';

const Heading = ({ children, classes = [] }) => {
    const style = useConsentFormsStyles();
    return (
        <Typography variant="h2" className={clsx(style.secondaryHeading, ...classes)}>
            {children}
        </Typography>
    );
};

export default Heading;
