//* * MUI */
import { MenuItem, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
//* * Others */
import clsx from 'clsx';
import { useMemo } from 'react';

import { grayCircle as greenCircle } from '@/assets/images/grayCircle';
//* * Icons */
import { greenBoxTick } from '@/assets/images/greenBoxTick';
import grayCircle from '@/assets/images/greenCircle.svg';

//* * Styles */
import useStepMenuStyles from './StepMenuStyles';

const Item = ({ children, highlight = false, showIcon = false, dropdown = false, disableIcon = false }) => {
    const classes = useStepMenuStyles();
    const theme = useTheme();

    const renderIcon = useMemo(() => {
        if (highlight) {
            return greenCircle.replace('#4C726D', theme.palette.common.secondary);
        }
        if (showIcon) {
            return greenBoxTick.replace('#4C726D', theme.palette.common.secondary);
        }
        return grayCircle;
    }, [highlight, showIcon, disableIcon]);

    return (
        <>
            {dropdown ? (
                <MenuItem disableRipple classes={{ root: classes.menuRoot }}>
                    <div className={clsx(classes.flex, classes.w100)}>
                        <div className={classes.mobileMenuItemIcon}>
                            <img src={renderIcon} className={clsx(classes.checkIcon)} alt="icon" />
                        </div>
                        <div className={classes.mobileMenuItemText}>
                            <Typography
                                className={clsx(
                                    classes.mobileMenuText,
                                    highlight ? classes.highlightText : null,
                                    classes.unSelect
                                )}
                            >
                                {children}
                            </Typography>
                        </div>
                    </div>
                </MenuItem>
            ) : (
                <Typography
                    className={clsx(classes.menuItem, highlight ? classes.highlightText : null, classes.unSelect)}
                >
                    <img
                        src={
                            renderIcon.includes('<svg')
                                ? `data:image/svg+xml;utf8,${encodeURIComponent(renderIcon)}`
                                : renderIcon
                        }
                        className={clsx(classes.checkIcon)}
                        alt="icon"
                    />
                    {children}
                </Typography>
            )}
        </>
    );
};

export default Item;
