import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { forwardRef, useCallback, useContext } from 'react';
/** Signature Canvas */
import ReactSignatureCanvas from 'react-signature-canvas';

/** Icons */
import restartIcon from '@/assets/images/restartIcon.svg';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import { useBookingStore } from '@/store.js';

/** Styles */
import useConsentFormsStyles from '../../ConsentFormsStyles';
import './SignaturePad.css';

const buttonProps = {
    disableElevation: true,
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
    color: 'primary',
};

const SignaturePad = forwardRef((props, signCanvas) => {
    const classes = useConsentFormsStyles();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    /** Context */
    const { consentFormsContent: content } = useContext(ConsentFormsContext);

    const isSignatureEmpty = useBookingStore((state) => state.isSignatureEmpty);

    /** Clear Signature */
    const clearSignature = () => {
        signCanvas?.current?.clear();
        useBookingStore.setState({ isSignatureEmpty: true });
    };

    const onStartDrawing = () => {
        useBookingStore.setState({ isSignatureEmpty: false });
    };

    const renderMobilePad = useCallback(() => {
        if (!isSignatureEmpty)
            return (
                <Button
                    startIcon={<img src={restartIcon} alt="Restart" />}
                    className={classes.restartBtn}
                    onClick={clearSignature}
                    {...buttonProps}
                >
                    {content ? content.buttons.startOver : 'Start over'}
                </Button>
            );
        return (
            <Typography className={clsx(classes.paragraph, classes.textUnSelect)}>
                {content ? content.signaturePopup.ownSignField : 'Draw inside the grey box'}
            </Typography>
        );
    }, [isSignatureEmpty, content, classes, clearSignature]);

    return (
        <div className={matchesXs ? classes.mx10 : null}>
            <div className={clsx(classes.signCanvasHeader, matchesXs ? classes.mt10 : null)}>
                {matchesXs ? (
                    renderMobilePad()
                ) : (
                    <>
                        <Typography className={clsx(classes.paragraph, classes.textUnSelect)}>
                            {content ? content.signaturePopup.ownSignField : 'Draw inside the grey box'}
                        </Typography>
                        <Button
                            startIcon={<img src={restartIcon} alt="Restart" />}
                            className={classes.restartBtn}
                            onClick={clearSignature}
                            {...buttonProps}
                        >
                            {content ? content.buttons.startOver : 'Start over'}
                        </Button>
                    </>
                )}
            </div>
            <ReactSignatureCanvas ref={signCanvas} onBegin={onStartDrawing} canvasProps={{ className: 'sigCanvas' }} />
        </div>
    );
});

export default SignaturePad;
