import { makeStyles } from '@material-ui/core/styles';

const useTableStyles = makeStyles((theme) => ({
    table: {},
    tableHead: {
        borderBottom: '2px solid #F5F5F5',
        paddingInline: '1.5rem',
        paddingBlock: '1rem',
        [theme.breakpoints.down('xs')]: {
            paddingInline: '.5rem',
        },
    },
    headTitle: {
        fontFamily: 'MessinaSans-Regular',
        color: '#ABABAB',
        fontSize: '12px',
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
            fontSize: '10px',
            wordBreak: 'break-all',
            paddingRight: 3,
        },
    },
    lastColumn: { textAlign: 'right' },
    row: {
        paddingInline: '1.5rem',
        paddingBlock: '1rem',
        borderBottom: '2px solid #F5F5F5',
        [theme.breakpoints.down('xs')]: {
            paddingInline: '.5rem',
        },
    },
    rowItem: {
        color: '#2E2E2E',
        fontSize: '1rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.7rem',
        },
    },
    fwBold: { color: '#000000', fontFamily: 'MessinaSans-SemiBold' },
    fwNormal: { fontFamily: 'MessinaSans-Regular' },
    footerButton: { marginTop: '1rem' },
}));

export default useTableStyles;
