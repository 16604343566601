//* * React */
import { Button, Menu, Typography } from '@material-ui/core';
//* * MUI */
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//* * Others */
import clsx from 'clsx';
import { useState } from 'react';

//* * Icons */
import sideBarArrow from '@/assets/images/sideBarArrow.png';
import sideBarArrowUp from '@/assets/images/sideBarArrowUp.png';

//* * Reusable Components */
import CardContainer from '../CardContainer';
import CircularProgressBar from './CircularProgressBar';
import Item from './MenuItem';
//* * Styles */
import useStepMenuStyles, { menuPropsStyles } from './StepMenuStyles';

const StepMenu = ({
    title,
    data = ['Personal Information', 'Contract details', 'Summary and Consent'],
    currentStepNo,
}) => {
    const classes = useStepMenuStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

    const [anchorE1, setAnchorE1] = useState(null);
    const [mobileCodeWidth, setMobileCodeWidth] = useState('0');

    const handleClick = (event) => {
        const mobileBtn = document.getElementById('mobileCodeButton').clientWidth;
        const main = document.getElementById('main_').clientWidth;
        const w = mobileBtn + main + 100;
        setMobileCodeWidth(w.toString());
        setAnchorE1(event.currentTarget);
    };

    const handleClose = () => setAnchorE1(null);
    const vertical = (position) => ({ vertical: position });

    return (
        <>
            {matchesSM ? (
                <>
                    <Button
                        id="mobileCodeButton"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        disableRipple
                        className={classes.formControl}
                        classes={{
                            label: classes.menuLabel,
                            root: clsx(classes.classRoot, anchorE1 ? classes.borderLeftNone : null),
                        }}
                    >
                        <div className={classes.loadingProgress}>
                            <CircularProgressBar value={((currentStepNo + 1) * 100) / data.length} />
                        </div>
                        <Typography className={classes.shortText} id="main_">
                            {title}
                        </Typography>
                        <img src={anchorE1 === null ? sideBarArrow : sideBarArrowUp} alt="arrow-icon" />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorE1}
                        keepMounted
                        open={Boolean(anchorE1)}
                        onClose={handleClose}
                        anchorOrigin={vertical('bottom')}
                        transformOrigin={vertical('top')}
                        PaperProps={{
                            style: {
                                width: parseFloat(mobileCodeWidth),
                                ...menuPropsStyles,
                            },
                        }}
                    >
                        {data &&
                            data.map((item, index) => (
                                <Item
                                    key={index}
                                    highlight={index === currentStepNo}
                                    showIcon={index < currentStepNo}
                                    disableIcon={index > currentStepNo}
                                    dropdown={matchesSM}
                                >
                                    {item}
                                </Item>
                            ))}
                    </Menu>
                </>
            ) : (
                <CardContainer classNames={[classes.sideMenu]}>
                    {data.map((item, index) => (
                        <Item
                            key={index}
                            highlight={index === currentStepNo}
                            showIcon={index < currentStepNo}
                            disableIcon={index > currentStepNo}
                        >
                            {item}
                        </Item>
                    ))}
                </CardContainer>
            )}
        </>
    );
};

export default StepMenu;
