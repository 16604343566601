import { Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AntSwitch } from '../AntSwitchCommon';
import { useColorStyles } from '../BasicStyles';
import InputFieldQuestions, { compType } from './InputFieldQuestions';

const useStyles = makeStyles((theme) => ({
    BtncontentText: {
        textTransform: 'none',
        fontFamily: 'MessinaSans-Regular',
        fontSize: 16,
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        margin: '20px 5px 0 0',
    },
    userData: {
        margin: '0 5px 0 0',
    },
}));

const PastConditionSwitch = ({
    mainData,
    switchName,
    savedValue,
    index,
    sergeriesArr1,
    setSergeriesArr1,
    handleFunction,
    checkBoxObj,
    errOthers,
    setErrOthers,
    errorObj,
    setErrorObj,
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const basicStyle = useColorStyles();

    const theme = useTheme();
    const [specific, setSpecific] = useState('');
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [date, setDate] = useState('');
    return (
        <>
            <div className={clsx(classes.mainContainer, index === 0 && basicStyle.mb30)}>
                <AntSwitch
                    checked={checkBoxObj[index] || false}
                    onChange={() => {
                        handleFunction(index);
                    }}
                    name={switchName}
                    className={classes.userData}
                />
                <Typography className={classes.BtncontentText}>{switchName}</Typography>
            </div>

            {checkBoxObj[index] && (
                <>
                    {sergeriesArr1.map(
                        (m, i) =>
                            m.pastCondition === savedValue && (
                                <InputFieldQuestions
                                    componentType={compType.EXISTING_CONDITIONS}
                                    mainData={mainData}
                                    idOfComponent="pastCondition"
                                    dateQuestion={m.whenPastCondition}
                                    getter={m.specific}
                                    isCompulsory={m.pastCondition}
                                    setDate={setDate}
                                    getterDate={date}
                                    index={index}
                                    callBack={(e) => {
                                        setSpecific(e);
                                        setSergeriesArr1((prev) => {
                                            const a = prev[i];
                                            a.specific = e;
                                            return prev;
                                        });
                                    }}
                                    callBackDate={(e) => {
                                        setSergeriesArr1((prev) => {
                                            const a = prev[i];
                                            a.whenPastCondition = e;
                                            return prev;
                                        });
                                    }}
                                    errOthers={errOthers}
                                    setErrOthers={setErrOthers}
                                    errorObj={errorObj}
                                    setErrorObj={setErrorObj}
                                />
                            )
                    )}
                </>
            )}
        </>
    );
};

export default memo(PastConditionSwitch);
