import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import clsx from 'clsx';
import { sortBy } from 'es-toolkit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';

import CardContainer from '@/components/Common/CardContainer.jsx';
import Spacer from '@/components/Common/Spacer.jsx';
import useConsentFormsStyles from '@/components/ConsentForms/ConsentFormsStyles.js';
import FilledButton from '@/components/FilledButton.jsx';
import Loader from '@/components/Loader.jsx';

import { ConsentAllFormsInitialRoutes, useConsentFormsContext } from '@/context/ConsentFormsContext.jsx';

import useProfessionalMappings from '@/hooks/useProfessionalMappings.js';

import { getByTag } from '@/utils/fetchers.js';
import { BASE_API_URL, getText } from '@/utils/helpers.js';

import { useCustomAnamnesisStore, useFormLocation, useSearchStore } from '@/store.js';

//* * CUSTOM STYLES */
const commonStyles = { fontFamily: 'MessinaSans-Regular', border: '1px solid #D8DADC', outline: 'none' };

const codeStyles = {
    ...commonStyles,
    height: 180,
    width: 104,
    borderRadius: 15,
    fontSize: 64,
};

const codeStylesTab = {
    ...commonStyles,
    height: 120,
    width: '80%',
    borderRadius: 10,
    fontSize: 40,
};

const codeStylesMobile = {
    ...commonStyles,
    height: 40,
    width: 25,
    borderRadius: 5,
    fontSize: 20,
};

const errorBorder = { border: '1px solid #da3030' };

const PUBLIC_API_PATH = 'public/user/form-data';

const AllFormsCode = () => {
    const classes = useConsentFormsStyles();

    const history = useHistory();
    const theme = useTheme();
    const { i18n, t } = useTranslation();

    //* * Context */
    const { setConsentAllForms, setProfessional } = useConsentFormsContext();

    useProfessionalMappings();

    // Location Store
    const formLocation = useFormLocation((state) => state);

    const professionals = useSearchStore((state) => state.professionals);

    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

    const codeStyle = () => {
        if (matchesXs) return codeStylesMobile;
        if (matchesSm || matchesMd) return codeStylesTab;
        return codeStyles;
    };

    const { data, isLoading: loading } = useSWRImmutable(
        {
            key: 'all-forms-code-prismic',
            tag: 'all_forms_code',
            params: {
                lang: i18n.language === 'en' ? 'en-us' : 'de-de',
            },
        },
        getByTag
    );
    const content = data?.results[0].data;

    // STATE: CODE - LOADING - ERROR
    const [{ code, isLoading, isError }, setState] = useState({
        code: '',
        isLoading: false,
        isError: false,
    });

    const handleChange = (newCode) => setState((prev) => ({ ...prev, code: newCode }));

    const handleContinue = async () => {
        setState((prev) => ({ ...prev, isLoading: true, isError: false }));
        try {
            const res = await axios.post(`${BASE_API_URL}/${PUBLIC_API_PATH}/codes/request`, {
                code: code,
                location: {
                    location_key: formLocation.hub?.api_key ?? '',
                    sub_loc_key: '',
                    dc_instance_id: formLocation.dc_instance_id ?? '',
                },
            });
            if (res.status === 200) {
                const { forms, professional } = res.data;

                const consentForms = [];
                const customAnamnesis = [];

                forms.forEach((form) => {
                    if (form.key) consentForms.push(form);
                    else customAnamnesis.push(form);
                });

                const sortedForms = sortBy(consentForms, ['key']);
                // context states
                setProfessional({
                    id: professional.professional_id,
                    key: professional.professional_key,
                    name: professionals[professional.professional_key]?.display_name[0].text,
                });
                setConsentAllForms((prev) => ({
                    ...prev,
                    isConsentAllForms: true,
                    forms: sortedForms,
                    initialRoute: ConsentAllFormsInitialRoutes.ALL_FORMS_CODE,
                }));

                // local state
                setState((prev) => ({ ...prev, isLoading: false, isError: false }));

                if (customAnamnesis.length > 0) {
                    useCustomAnamnesisStore.setState({
                        redirectToConsentForms: sortedForms.length > 0,
                        formsToFill: customAnamnesis,
                        currentForm: 1,
                        loading: true,
                    });
                    history.push(
                        `/custom-anamnesis?professional_id=${professional.professional_id}&template_id=${customAnamnesis[0].id}`
                    );
                } else {
                    // redirects to welcome page
                    history.push('/consent-all-forms-welcome');
                }
            }
        } catch (error) {
            setState((prev) => ({ ...prev, isLoading: false, isError: true }));
            console.debug('error while loading', 'error while loading');
            console.debug(error.message);
        }
    };

    useEffect(() => {
        // redirect back if no location is selected
        if (Object.keys(formLocation.hub).length === 0) {
            history.replace('/all-forms-location');
        }
    }, [formLocation, history]);

    if (loading) return <Loader />;
    return (
        <div
            className={clsx(classes.ContainerBody, classes.center, classes.mt0, classes.relativePosition)}
            style={{ paddingTop: '2rem' }}
        >
            <Grid container>
                <div className={clsx(classes.w100, classes.dFlex, classes.contentEnd)}>
                    <Typography
                        className={clsx(classes.subHeading, classes.mb35, classes.cursorPointer)}
                        onClick={() => history.push('/all-forms-location')}
                    >
                        {getText(formLocation.hub?.api_value) ?? ''}
                    </Typography>
                </div>
                <Grid item xs={12}>
                    <CardContainer>
                        <Typography variant="h5" className={clsx(classes.subHeading, classes.mt30, classes.textCenter)}>
                            {getText(content?.subtitle) ?? t('AllFormsSecondaryHeading')}
                        </Typography>

                        {!matchesXs && <Spacer y={80} />}

                        <div
                            className={clsx(
                                classes.dFlex,
                                classes.contentCenter,
                                classes.itemsCenter,
                                classes.my30,
                                classes.codeContainer
                            )}
                        >
                            <OtpInput
                                value={code}
                                onChange={handleChange}
                                numInputs={6}
                                isInputNum
                                inputStyle={codeStyle()}
                                hasErrored={isError}
                                errorStyle={errorBorder}
                                className={classes.CodeBox}
                            />
                        </div>
                        {isError && (
                            <Typography
                                variant="body1"
                                className={clsx(
                                    classes.textCenter,
                                    classes.paragraph,
                                    classes.colorError,
                                    classes.mb40
                                )}
                            >
                                {getText(content?.code_error) ?? t('AllFormsCodeError')}
                            </Typography>
                        )}

                        {!matchesXs && <Spacer y={80} />}

                        <div className={clsx(classes.dFlex, classes.contentCenter, classes.mb30)}>
                            <FilledButton
                                text={getText(content?.cta_continue) ?? t('Continue')}
                                onPress={handleContinue}
                                disabled={code.length !== 6 || isLoading}
                                loading={isLoading}
                            />
                        </div>
                    </CardContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default AllFormsCode;
