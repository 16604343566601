import { useContext, useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import { Context as AuthContext } from '@/context/AuthContext.js';
import { Context as ConsentManagerContext } from '@/context/ConsentManagerContext.js';

import { getSharedDocuments } from '@/utils/helpers.js';

import { useProfessionalsStore, useSearchStore } from '@/store.js';

import useProfessionalMappings from './useProfessionalMappings.js';

const fetcher = async ({ userId }) => getSharedDocuments({ userId }).then((response) => response.data);

const useDoctorDocuments = () => {
    const {
        state: { user },
    } = useContext(AuthContext);
    const { data: documents } = useSWRImmutable(
        user?.id
            ? {
                  userId: user?.id,
                  key: 'shared-documents',
              }
            : null,
        fetcher
    );
    const professionalProfiles = useSearchStore((state) => state.professionals);
    const professionalMappings = useProfessionalsStore((state) => state.professionalMappings);
    const { setDocuments: setDocumentsContext } = useContext(ConsentManagerContext);

    const [sharedDocuments, setSharedDocuments] = useState(null);

    useProfessionalMappings();

    useEffect(() => {
        if (!documents || !professionalMappings) return;

        const mappingsReducedToId = Object.values(professionalMappings).reduce((obj, mapping) => {
            // eslint-disable-next-line no-param-reassign
            obj[mapping.user_id] = mapping;

            return obj;
        }, {});

        const filteredDocuments = [];
        documents.forEach((document) => {
            const mappingMatch = mappingsReducedToId[document.sharing?.[0].shared_target.id];

            if (!mappingMatch) return;

            const matchingProfessional = professionalProfiles[mappingMatch.prismic_key];

            filteredDocuments.push({
                ...document,
                ...matchingProfessional,
                doctor_name: matchingProfessional?.display_name[0].text ?? '-',
                created_date: document.meta.created_at,
                doc_type: document.type,
            });
        });

        setDocumentsContext(filteredDocuments);
        setSharedDocuments(filteredDocuments);
    }, [professionalMappings, documents]);

    return { doctorDocuments: sharedDocuments };
};

export default useDoctorDocuments;
