import { Grid, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { AntSwitch } from '../../Common/AntSwitchCommon';
import useConsentFormsStyles from '../ConsentFormsStyles';

const ConfirmSummary = ({
    heading = 'Heading',
    text = 'paragraph...',
    //* * Switch Props */
    onToggle = () => {},
    checked = false,
}) => {
    /** Styles */
    const classes = useConsentFormsStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <div>
            <div>
                <Typography variant="h2" className={clsx(classes.mainHeading, classes.my10)}>
                    {heading}
                </Typography>
            </div>
            <Grid container>
                <Grid xs={1}>
                    <AntSwitch className={classes.my15} onChange={onToggle} checked={checked} />
                </Grid>
                <Grid xs={11}>
                    <Typography className={clsx(classes.paragraph, classes.my10, ...(matchesXs ? [classes.pl25] : []))}>
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default ConfirmSummary;
