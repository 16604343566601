import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { replaceProfessionalType } from '@/utils/helpers.js';

import useScrollToTop from '../hooks/useScrollToTop.js';
import { useColorStyles } from './Common/BasicStyles.js';
import ToolTipBox from './Common/ToolTipBox.jsx';
import FilledButton from './FilledButton.jsx';
import OutlinedButton from './OutlinedButton.jsx';

const useStyles = makeStyles((theme) => ({
    heading: {
        ...theme.typography.HL1,
        fontWeight: 'bold',
    },
    contentText: {
        ...theme.typography.HL5,
    },
    mainCenterCircle: {
        display: 'grid',
        height: ({ isInBookingModal }) => (isInBookingModal ? '100%' : '330px'),
        justifyContent: 'center',
        alignItems: 'center',
        overflow: ({ isInBookingModal }) => (isInBookingModal ? 'hidden' : 'visible'),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    mainButtonsDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

const PatientTypeSelection = ({ doctor, setPatientType, mainData, loading, isInBookingModal = false }) => {
    const classes = useStyles({ isInBookingModal });
    const { i18n } = useTranslation();
    const colorStyle = useColorStyles();

    useScrollToTop();

    if (loading) return null;

    return (
        <div className={classes.mainCenterCircle}>
            <Typography className={clsx(classes.contentText, colorStyle.mt5)}>
                {replaceProfessionalType({ text: mainData.heading[0].text, language: i18n.language })}
            </Typography>
            <div className={classes.mainButtonsDiv}>
                <ToolTipBox
                    text={
                        doctor?.allow_new_patients === false || !doctor?.is_for_new_patients
                            ? mainData?.infotip_for_new_patient_cta_disabled?.[0]?.text
                            : ''
                    }
                >
                    <div className={colorStyle.m10}>
                        <FilledButton
                            disabled={!doctor?.is_for_new_patients}
                            text={mainData?.new_patient_button[0].text}
                            onPress={() => setPatientType(false)}
                        />
                    </div>
                </ToolTipBox>
                <ToolTipBox
                    text={
                        !doctor?.is_for_returning_patients
                            ? mainData?.infotip_for_returning_patient_cta_disabled?.[0]?.text
                            : ''
                    }
                >
                    <div>
                        <OutlinedButton
                            disabled={!doctor?.is_for_returning_patients}
                            text={mainData?.current_patient_button[0].text}
                            onPress={() => setPatientType(true)}
                        />
                    </div>
                </ToolTipBox>
            </div>
        </div>
    );
};

export default PatientTypeSelection;
