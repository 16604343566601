import { makeStyles } from '@material-ui/core/styles';

const useTableStyles = makeStyles((theme) => ({
    table: { marginBlock: '1rem', [theme.breakpoints.down('xs')]: { marginBlock: 0 } },
    colHeading: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        cursor: 'pointer',
        '&::-webkit-user-select': 'none' /* Safari */,
        '&::-ms-user-select': 'none' /* IE 10 and IE 11 */,
        userSelect: 'none',
    },
    sortIcons: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        [theme.breakpoints.down('xs')]: { padding: '10px' },
    },
    sortIcon: { cursor: 'pointer', height: '6px' },
    colHeadingText: {
        fontFamily: 'MessinaSans-SemiBold',
        fontSize: '12px',
        fontStyle: 'normal',
        textTransform: 'uppercase',
        color: theme.palette.common.brown,
        [theme.breakpoints.down('xs')]: { fontSize: '10px' },
    },
    textCenter: { textAlign: 'center' },
    textRight: { textAlign: 'right' },
    textLeft: { textAlign: 'left' },
    mt10: { marginTop: 10 },
    icon: { fontSize: '2rem', color: theme.palette.common.brown },
    tableHeader: { borderBottom: '3px solid #F5F5F5', padding: '1rem' },
    rowAccordian: {
        width: '100%',
        boxShadow: 'none',
        backgroundColor: 'none',
        borderBottom: '3px solid #F5F5F5',
        '&.Mui-expanded': {
            margin: 0,
        },
        '&:before': {
            backgroundColor: 'transparent',
        },
    },
    rowsContainer: { width: '100%' },
    row: { paddingBlock: '1rem' },
    rowIcon: {
        width: '24px',
        height: '24px',
        objectFit: 'contain',
        [theme.breakpoints.down('xs')]: { width: '18px', height: '18px' },
    },
    buttonIcon: { width: '1rem', height: '1rem', objectFit: 'contain' },
    rowText: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        color: theme.palette.common.darkGrey,
        [theme.breakpoints.down('xs')]: { fontSize: '14px' },
    },
    rowType: { display: 'flex', alignItems: 'center', gap: 10 },
    rowTypeText: {
        paddingRight: '10px',
        fontFamily: 'MessinaSans-SemiBold',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
        [theme.breakpoints.down('sm')]: { fontSize: '14px' },
    },
    rowExpanded: { flexDirection: 'column', padding: 0 },
    rowExpandedBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'flex-start',
        paddingBottom: '1.5rem',
        paddingInline: '1rem',
        borderBottom: '3px solid #F5F5F5',
        [theme.breakpoints.down('xs')]: { paddingInline: '1rem' },
    },
    rowExpandedFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingInline: '1rem',
        paddingBlock: '.6rem',
    },
    rowFooterButton: {
        background: 'transparent',
        borderRadius: '5px',
        margin: '.4rem',
        '&:hover': {
            background: 'transparent',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '.2rem 0',
        },
    },
    rowFooterButtonText: {
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 600,
        color: theme.palette.common.brown,
        fontSize: '1rem',
        textTransform: 'capitalize',
        [theme.breakpoints.down('xs')]: { fontSize: '14px' },
    },
    fileName: {
        fontFamily: 'MessinaSans-Regular',
        [theme.breakpoints.down('sm')]: { fontSize: '14px' },
    },
    btnContainer: {
        marginTop: '1rem',
    },
    showMoreButton: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: '1rem',
        textTransform: 'none',
        '&:hover': {
            background: 'transparent',
        },
    },
    rowItemHeight: {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },
    jcEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    mb1: {
        marginBottom: 5,
    },
    pX30: {
        paddingInline: 30,
    },
    downloadButton: {
        position: 'absolute',
        top: 0,
        display: 'none',
        pointerEvents: 'none',
    },
}));

export default useTableStyles;
