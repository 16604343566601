import axios from 'axios';

import { processAPIWithHeaders } from '@/utils/helpers.js';

import { useConfigStore } from '@/store.js';

const BASE_API_URL = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_DOMAIN}`;
const BASE_API_URL_FOR_PRACTITIONERS = `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_PRAC_API_DOMAIN}`;

export async function getUserAppointments(usersession) {
    const user_id = usersession?.idToken.payload.sub;
    const token = usersession?.idToken.jwtToken;
    try {
        const response = await axios.get(`${BASE_API_URL}/secure/user/appointments/?user_id=${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        // console.debug("response", response)
        return response;
    } catch (error) {
        console.debug('error is', error);
        return [];
    }
}

export const getAppointment = async (userSession, appointmentId) => {
    const user_id = userSession?.idToken.payload.sub;
    const token = userSession?.idToken.jwtToken;
    try {
        const response = await axios.get(
            `${BASE_API_URL}/secure/user/appointments/${appointmentId}?user_id=${user_id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
            }
        );

        return response.data;
    } catch (e) {
        console.debug(e);
        return null;
    }
};

export async function cancelAppointmentForUser(
    usersession,
    appointment_id,
    user_id,
    customerId,
    confirmCancellation = false
) {
    const token = usersession?.idToken.jwtToken;
    try {
        const customer = useConfigStore.getState().currentCustomer;
        const fallback_customer_id = customer?.customer_id?.[0].text;
        const response = await processAPIWithHeaders(
            `secure/user/appointments/${
                appointment_id
            }/${confirmCancellation ? 'confirm-cancelled' : 'cancel'}?user_id=${user_id}`,
            'POST',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                    customer_id: customerId ?? fallback_customer_id ?? '',
                },
            }
        );
        console.debug('response of cancel', response);
        return response;
    } catch (error) {
        console.debug('error is', error);
        return new Error(error.message);
    }
}

export async function getPractitionerForUser(usersession) {
    const user_id = usersession.idToken.payload.sub;
    const token = usersession.idToken.jwtToken;

    try {
        const response = await axios.get(`${BASE_API_URL}/secure/user/appointments/professionals?user_id=${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        });
        return response;
    } catch (error) {
        return new Error(error.message);
    }
}

export async function getCalenderForUser(
    user_id,
    insurance_type_key,
    is_returning,
    is_online,
    startDate,
    treatmentsString,
    sub_loc_key,
    dc_calendar_id,
    customer_id
) {
    console.debug('startDate : ', startDate, is_returning, is_online, insurance_type_key, user_id, treatmentsString);
    const customer = useConfigStore.getState().currentCustomer;
    const fallback_customer_id = customer?.customer_id?.[0].text;
    try {
        const response = await axios.get(
            `${BASE_API_URL_FOR_PRACTITIONERS}/public/users/availability/${user_id}?appointment_type_keys=${treatmentsString}&insurance_type_key=${insurance_type_key}&is_returning=${is_returning}&is_online=${is_online}&start_date=${startDate}${
                sub_loc_key ? `&sub_loc_key=${sub_loc_key}` : ''
            }&dc_calendar_id=${dc_calendar_id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    customer_id: customer_id ?? fallback_customer_id ?? '',
                },
            }
        );
        console.debug('response OF RESHudule ', response);
        return response;
    } catch (error) {
        console.debug('error is', error);
        return [];
    }
}

export async function calenderUpdated(
    usersession,
    appointment_id,
    user_id, // eterno user id
    patient_id, // dc user id
    is_for_self,
    is_returning,
    is_public_insurance,
    is_private_insurance,
    document,
    symptom,
    additional_info,
    professional_id,
    professional_key,
    professional_type_key,
    location_key,
    services,
    type_keys,
    dateSlot,
    durationValue,
    durationUnits,
    type_id,
    calender_id,
    practitioner_id, // dc_practitioner_id
    is_at_location,
    is_online,
    sub_loy_key,
    customer_id
) {
    const token = usersession.idToken.jwtToken;
    console.debug('activeR', calender_id, document, symptom);

    try {
        const customer = useConfigStore.getState().currentCustomer;
        const fallback_customer_id = customer?.customer_id?.[0].text;
        const response = await processAPIWithHeaders(
            `secure/user/appointments/${appointment_id}/reschedule?user_id=${user_id}`,
            'POST',
            {
                body: {
                    patient: {
                        id: user_id, // eterno user id
                        dc_user_id: patient_id, // dc user id
                        is_for_self: is_for_self,
                        is_returning: is_returning,
                        is_public_insurance: is_public_insurance,
                        is_private_insurance: is_private_insurance,
                        documents: document,
                        symptoms: symptom,
                        additional_info: additional_info,
                    },
                    professional: {
                        id: professional_id,
                        key: professional_key,
                        type_key: professional_type_key,
                        location_key: location_key,
                        sub_loc_key: sub_loy_key,
                    },
                    appointment: {
                        id: appointment_id,
                        services: [services],
                        type_keys: [type_keys],
                        slot: dateSlot,
                        duration: {
                            value: durationValue,
                            units: durationUnits,
                        },
                        meta: {
                            type_id: type_id,
                            calendar_id: calender_id,
                            user_id: practitioner_id,
                            is_at_location: is_at_location,
                            is_online: is_online,
                        },
                    },
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                    customer_id: customer_id ?? fallback_customer_id ?? '',
                },
            }
        );
        console.debug('response of reschedule', response);
        return response;
    } catch (error) {
        console.debug('error of reschedule', error);
        return [];
    }
}
