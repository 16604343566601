import { Grid, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import communication from '@/assets/images/communication.svg';
import pencilIcon from '@/assets/images/pencilIcon.png';

import { ConsentFormsContext } from '@/context/ConsentFormsContext';

import useConsentFormsStyles from '../ConsentFormsStyles';
import ListItem from './ListItem';

const SummaryCommunication = () => {
    const classes = useConsentFormsStyles();
    const history = useHistory();

    /** Context */
    const {
        consentFormsContent: content,
        communicationData,
        communicationToggles,
        isAllForms,
        movePage,
        whichEnum,
    } = useContext(ConsentFormsContext);

    const { email, sms, other } = communicationData;
    const { emailToggle, smsToggle, otherToggle } = communicationToggles;

    /** Edit personal information */
    const handleEdit = () => {
        if (isAllForms) {
            movePage(whichEnum.PREVIOUS, history);
        }
        history.push(`${history.location.pathname}?step=communication-and-contract`);
    };

    return (
        <div className={classes.summaryPersonalInfo}>
            {/* Header */}
            <div className={classes.personalInfoHeader}>
                <img src={communication} className={classes.personalInfoIcon} />
                <Typography variant="body1" className={classes.shortHeading}>
                    {content?.heraklesContent?.communicationTitle || 'Kommunikationsweg'}
                </Typography>
            </div>

            {/* Personal Information */}
            <Grid container className={clsx(classes.personalInfoData, classes.my10)}>
                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content?.heraklesContent?.emailSummary || 'E-Mail'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{(emailToggle ? email : '') || '- -'}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content?.heraklesContent?.phoneSummary || 'Telefonnummer'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{(smsToggle ? sms : '') || '- -'}</ListItem>
                    </Grid>
                </Grid>

                <Grid container className={classes.mb10}>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem>{content?.heraklesContent?.otherSummary || 'Sonstige'}</ListItem>
                    </Grid>
                    <Grid md={6} sm={6} xs={12} className={classes.personalInfoDataFields}>
                        <ListItem bold>{(otherToggle ? other : '') || '- -'}</ListItem>
                    </Grid>
                </Grid>
            </Grid>

            {/* Edit Icon */}
            <IconButton onClick={handleEdit} className={classes.editButton} disableRipple>
                <img src={pencilIcon} className={classes.editIcon} />
            </IconButton>
        </div>
    );
};

export default SummaryCommunication;
