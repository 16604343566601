import { Grid, Typography } from '@material-ui/core';

import { DateTextField } from '../../Common/Date/DateTextField';
import useConsentFormsStyles from '../ConsentFormsStyles';

const CourseDate = ({
    heading,
    label = 'Kursstart Datum',
    setDate = () => {},
    date = '',
    dateError = '',
    errorMessage = '',
    setError = () => {},
}) => {
    /** Styles */
    const classes = useConsentFormsStyles();
    return (
        <Grid container spacing={1} direction="row">
            <Typography className={classes.secondaryHeading}>{heading || 'Lorem_ipsum'}</Typography>
            <Grid container spacing={1}>
                <Grid item md={12} xs={12} direction="row">
                    <DateTextField
                        type="insuranceDate"
                        separator="."
                        mainHeading={label || 'Kursstart Datum'}
                        setDOB={setDate}
                        dob={date}
                        setError={setError}
                        error={dateError}
                        errorMessage={errorMessage}
                        placeHolderMonth="MM"
                        placeHolderYear="YYYY"
                        placeHolderDay="DD"
                        mt={10}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CourseDate;
